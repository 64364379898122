import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, IconButton, Switch, FormControlLabel } from '@mui/material';
import { PlayArrow, Pause } from '@mui/icons-material'
import { keyframes } from '@mui/system'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import { Document, Page, pdfjs } from 'react-pdf';
import HTMLFlipBook from 'react-pageflip';
import api from '../../services/apiService';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import languageStore from '../../stores/languageStore';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function useWindowSize() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const onResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, []);

  return size;
}

const StoryShareViewer = () => {
  const { language } = languageStore(); // 獲取當前語言
  const { share_code } = useParams();
  const { t } = useTranslation();
  const [storyData, setStoryData] = useState(null);
  const [pdfUrl, setPdfUrl] = useState('');
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1)
  const [isFullScreen, setIsFullScreen] = useState(false);
  const containerRef = useRef();
  const bookRef = useRef();
  const { width: winWidth, height: winHeight } = useWindowSize();
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const [isPlayingDefault, setIsPlayingDefault] = useState(false) // 是否正在播放中文
  const [isPlayingEn, setIsPlayingEn] = useState(false) // 是否正在播放英文
  const audioRef = useRef(new Audio()) // 音訊播放器
  const [selectedUserId, setSelectedUserId] = useState(null)
  const [selectedStoryId, setSelectedStoryId] = useState(null)
  const [currentAudioFilename, setCurrentAudioFilename] = useState(null);
  const [isAutoPlay, setIsAutoPlay] = useState(true); // 🔹 自動播放開關

  const PDF_WIDTH = 1440
  const PDF_HEIGHT = 942

  const scaleWidth = (isFullScreen ? winWidth : winWidth) / PDF_WIDTH
  const scaleHeight = (isFullScreen ? winHeight : winHeight) / PDF_HEIGHT
  const scale = Math.min(scaleWidth, scaleHeight)
  const scaledWidth = Math.round(PDF_WIDTH * scale * 0.97)
  const scaledHeight = Math.round(PDF_HEIGHT * scale * 0.97)

  useEffect(() => {
    const fetchStory = async () => {
      try {
        const response = await api.get(`/api/stories/sharestory/${share_code}`);
        setStoryData(response.data);
        setSelectedUserId(response.data.userId);
        setSelectedStoryId(response.data.storyId);
      } catch (error) {
        Swal.fire(t('error.fetchStoriesFailed'), error.message, 'error');
      }
    };
    fetchStory();
  }, [share_code, t]);

  const playAudio = async (langKey) => {
    console.log('currentPage', currentPage);
    if (!selectedUserId || !selectedStoryId || !currentPage) return;

    var page = currentPage - 2;
    var lang = langKey;
    if (lang !== 'en') {
      lang = 'default';
    }
    const filename = `${selectedStoryId}_${page}_${lang}.mp3`;

    // 🔹 如果目前的音檔名稱相同，且音檔只是暫停，那就直接繼續播放
    if (audioRef.current.src && currentAudioFilename === filename && audioRef.current.paused &&
      audioRef.current.currentTime > 0) {
      try {
        await audioRef.current.play().catch((error) => {
          console.error("音訊播放失敗:", error);
        });
      } catch (error) {
        console.error("音訊播放異常:", error);
      }
      if (langKey === 'en') {
        setIsPlayingEn(true);
        setIsPlayingDefault(false);
      } else {
        setIsPlayingDefault(true);
        setIsPlayingEn(false);
      }
      return;
    }

    try {
      const response = await api.get(`/api/stories/getaudiofile/${selectedUserId}/${filename}`, {
        responseType: 'blob'
      });

      const audioUrl = URL.createObjectURL(response.data);
      audioRef.current.src = audioUrl;
      try {
        await audioRef.current.play().catch((error) => {
          console.error("音訊播放失敗:", error);
        });
      } catch (error) {
        console.error("音訊播放異常:", error);
      }
      setCurrentAudioFilename(filename); // 更新目前播放的音檔名稱

      if (langKey === 'en') {
        setIsPlayingEn(true);
        setIsPlayingDefault(false);
      } else {
        setIsPlayingDefault(true);
        setIsPlayingEn(false);
      }

      audioRef.current.onended = () => {
        setIsPlayingEn(false);
        setIsPlayingDefault(false);
        setCurrentAudioFilename(null); // 音檔播放完畢後清除
      };
    } catch (error) {
      console.error(`Error loading ${langKey} audio:`, error);
      Swal.fire({
        title: t('common.error'),
        text: t('error.audioLoadError'),
        icon: 'error',
        confirmButtonText: t('common.ok')
      });
    }
  };

  const togglePlayPause = (langKey) => {
    if (!isFullScreen) return;
    if (langKey === 'en') {
      if (isPlayingEn) {
        audioRef.current.pause();
        setIsPlayingEn(false);
      } else {
        playAudio('en');
      }
    } else {
      if (isPlayingDefault) {
        audioRef.current.pause();
        setIsPlayingDefault(false);
      } else {
        playAudio('default');
      }
    }
  };

  useEffect(() => {
    audioRef.current.currentTime = 0;
    audioRef.current.pause(); // 暫停播放
    audioRef.current.src = ""; // 清除音源，避免下一次繼續播放
    setIsPlayingDefault(false);
    setIsPlayingEn(false);
    if (isFullScreen && isAutoPlay && currentPage >= 3 && currentPage < 15) {
      const timeout = setTimeout(() => {
        playAudio(language); // 延遲 1.5 秒後播放
      }, 1500);

      return () => clearTimeout(timeout); // 清除計時器，避免重複執行
    }
  }, [currentPage, isFullScreen, isAutoPlay]);

  useEffect(() => {

    const handleExitFullScreen = () => {
      const fsEl =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement;

      if (!fsEl) {
        setCurrentPage(1);
        console.log('🔴 Fullscreen exited');
        setIsFullScreen(false);
        // 停止音訊並清除
        audioRef.current.currentTime = 0;
        audioRef.current.pause();
        audioRef.current.src = "";
        setIsPlayingDefault(false);
        setIsPlayingEn(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        console.log('🔴 ESC pressed, exiting fullscreen');
        handleExitFullScreen();
      }
    };

    document.addEventListener('fullscreenchange', handleExitFullScreen);
    document.addEventListener('webkitfullscreenchange', handleExitFullScreen);
    document.addEventListener('mozfullscreenchange', handleExitFullScreen);
    document.addEventListener('MSFullscreenChange', handleExitFullScreen);
    document.addEventListener('keydown', handleKeyDown); // 監聽 ESC 鍵
    return () => {
      document.removeEventListener('fullscreenchange', handleExitFullScreen);
      document.removeEventListener('webkitfullscreenchange', handleExitFullScreen);
      document.removeEventListener('mozfullscreenchange', handleExitFullScreen);
      document.removeEventListener('MSFullscreenChange', handleExitFullScreen);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const loadFile = async (filename) => {
    if (filename) {
      try {
        const response = await api.get(`/api/stories/getsharefile/${share_code}/${filename}`, {
          responseType: 'blob',
        });
        return URL.createObjectURL(response.data);
      } catch (error) {
        Swal.fire(t('error.loadFileFailed'), error.message, 'error');
        return '';
      }
    }
    return '';
  };

  const spinAnimation = keyframes`
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
`

  const handleReadStoryClick = async () => {
    try {
      const url = await loadFile(storyData.pdf_filename);
      setPdfUrl(url);
      setIsFullScreen(true);
      setCurrentPage(1);
      setNumPages(null);

      if (containerRef.current) {
        let fullscreenRequested = false;

        if (containerRef.current.requestFullscreen) {
          containerRef.current.requestFullscreen().then(() => {
            fullscreenRequested = true;
          }).catch(err => {
            console.warn("Fullscreen API error:", err);
          });
        } else if (containerRef.current.mozRequestFullScreen) {
          containerRef.current.mozRequestFullScreen();
          fullscreenRequested = true;
        } else if (containerRef.current.webkitRequestFullscreen) {
          containerRef.current.webkitRequestFullscreen();
          fullscreenRequested = true;
        } else if (containerRef.current.msRequestFullscreen) {
          containerRef.current.msRequestFullscreen();
          fullscreenRequested = true;
        }

        // 🔹 等 1 秒後確認是否真的進入了全螢幕
        setTimeout(() => {
          if (!document.fullscreenElement) {
            console.warn("🔴 Fullscreen request failed, using fullscreen-container");
            setIsFullScreen(true);  // ✅ 只有當 requestFullscreen 失敗時才使用 fullscreen-container
          }
        }, 1000);
      }
    } catch (error) {
      Swal.fire(t('error.pdfLoadError'), error.message, 'error');
    }
  };

  return (
    <div className="container mx-auto p-6">
      {storyData && (
        <Helmet>
          <title>{storyData.title}</title>
          <meta name="title" content={storyData.title} />
          <meta name="description" content={storyData.description} />
          <meta property="og:title" content={storyData.title} />
          <meta property="og:description" content={storyData.description} />
          <meta
            property="og:image"
            content={`${apiBaseUrl}/api/stories/getsharefile/${share_code}/${storyData.coverImage}`}
          />
          <meta property="og:image:width" content="1200" />
          <meta property="og:image:height" content="630" />
          <meta property="og:url" content={window.location.href} />
          <meta property="og:type" content="website" />
        </Helmet>
      )}

      {storyData ? (
        <div className="text-center">
          <img
            src={`${apiBaseUrl}/api/stories/getsharefile/${share_code}/${storyData.coverImage}`}
            alt={storyData.title}
            className="w-64 h-96 object-cover mx-auto cursor-pointer"
            onClick={handleReadStoryClick}
          />
          <h2 className="text-3xl font-bold my-4">{storyData.title}</h2>
          <h2 className="text-2xl font-bold my-4">{storyData.eng_title}</h2>
          <p className="text-lg my-2">{storyData.description}</p>
          <button
            className="bg-blue-500 text-white py-2 px-6 rounded-full mt-4"
            onClick={handleReadStoryClick}
          >
            {t('category.readStory')}
          </button>
        </div>
      ) : (
        <p>{t('common.loading')}</p>
      )}

      {isFullScreen && pdfUrl && (
        <div ref={containerRef} className={document.fullscreenElement ? "" : "fullscreen-container"}>
          <div>
            {/* 右上角播放/暫停按鈕 */}
            {currentPage >= 3 && currentPage < 15 && (
              <Box
                sx={{
                  position: "absolute",
                  bottom: { xs: "10%", sm: "20%" },
                  left: "50%",
                  transform: "translateX(-50%)",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 10,
                  bgcolor: "rgba(0, 0, 0, 0.7)",
                  px: { xs: 0.1, sm: 0.2 },
                  py: { xs: 0.1, sm: 0.1 },
                  borderRadius: 20,
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                  width: "fit-content",
                  fontSize: { xs: "0.3rem", sm: "0.4rem", md: "0.5rem" }
                }}
              >
                {language === "en" ? (
                  <IconButton
                    onClick={() => togglePlayPause("en")}
                    sx={{
                      color: isPlayingEn ? "#FFEA00" : "#FFFFFF",
                      mx: 0.3,
                      "&:hover": { opacity: 0.8 },
                      fontSize: { xs: "0.6rem", sm: "0.8rem", md: "1rem" }
                    }}
                  >
                    {isPlayingEn ? <Pause fontSize="inherit" /> : <PlayArrow fontSize="inherit" />}
                    <span style={{ marginLeft: 2, fontSize: "inherit" }}>English</span>
                  </IconButton>
                ) : (
                  <>
                    <IconButton
                      onClick={() => togglePlayPause("default")}
                      sx={{
                        color: isPlayingDefault ? "#00E676" : "#FFFFFF",
                        mx: 0.3,
                        "&:hover": { opacity: 0.8 },
                        fontSize: { xs: "0.6rem", sm: "0.8rem", md: "1rem" }
                      }}
                    >
                      {isPlayingDefault ? <Pause fontSize="inherit" /> : <PlayArrow fontSize="inherit" />}
                      <span style={{ marginLeft: 2, fontSize: "inherit" }}>{t("lang_full_name")}</span>
                    </IconButton>
                    <IconButton
                      onClick={() => togglePlayPause("en")}
                      sx={{
                        color: isPlayingEn ? "#FFEA00" : "#FFFFFF",
                        mx: 0.3,
                        "&:hover": { opacity: 0.8 },
                        fontSize: { xs: "0.6rem", sm: "0.8rem", md: "1rem" }
                      }}
                    >
                      {isPlayingEn ? <Pause fontSize="inherit" /> : <PlayArrow fontSize="inherit" />}
                      <span style={{ marginLeft: 2, fontSize: "inherit" }}>English</span>
                    </IconButton>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={isAutoPlay}
                          onChange={() => setIsAutoPlay(!isAutoPlay)}
                          sx={{
                            "& .MuiSwitch-switchBase.Mui-checked": { color: "#00E676" },
                            "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": { backgroundColor: "#00E676" }
                          }}
                        />
                      }
                      label={t("common.autoPlay")}
                      TypographyProps={{
                        sx: {
                          fontSize: { xs: "0.2rem", sm: "0.3rem", md: "0.4rem" }
                        }
                      }}
                      sx={{
                        color: "#FFFFFF",
                        ml: 0.3
                      }}
                    />
                  </>
                )}
              </Box>
            )}

            <Document
              file={pdfUrl}
              onLoadSuccess={({ numPages }) => {
                setTimeout(() => {
                  setNumPages(numPages);  // ✅ 確保 PDF 加載成功時，更新頁數
                }, 1500);
                setCurrentPage(1);  // ✅ 讓書籍從第一頁開始
                console.log(`PDF 總頁數: ${numPages}`);
              }}
            >
              {numPages ? (
                <div className="flipbook-container">
                  <HTMLFlipBook
                    width={scaledWidth}
                    height={scaledHeight}
                    size="fixed"
                    className="mx-auto"
                    showCover={false}
                    usePortrait={true}
                    flippingTime={1000}
                    drawShadow={true}
                    autoSize={false}
                    mobileScrollSupport={true}
                    ref={bookRef}
                    onFlip={(e) => setCurrentPage(e.data + 1)}
                  >
                    {Array.from({ length: numPages }, (_, index) => (
                      <div key={index}>
                        <Page pageNumber={index + 1} width={scaledWidth} height={scaledHeight} />
                      </div>
                    ))}
                  </HTMLFlipBook>
                </div>
              ) : (
                <div className="loading-container">
                  <AutorenewIcon
                    sx={{
                      fontSize: 40,
                      animation: `${spinAnimation} 1s linear infinite`
                    }}
                  />
                </div>
              )}
            </Document>
          </div>
        </div >
      )}
    </div >
  );
};

export default StoryShareViewer;
