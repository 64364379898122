import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Dialog, AppBar, Toolbar, IconButton, Typography, Button, Slider, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
import { useTranslation } from 'react-i18next';

const CroppingModal = ({ open, setOpen, imageSrc, onCropComplete }) => {
  const { t } = useTranslation();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1.5);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [cropSize, setCropSize] = useState(null);

  const containerRef = useRef(null);

  const containerRefCallback = useCallback((node) => {
    if (node !== null) {
      containerRef.current = node;
      updateCropSize();
    }
  }, []);

  const updateCropSize = useCallback(() => {
    if (containerRef.current) {
      const containerWidth = containerRef.current.offsetWidth;
      const cropWidth = containerWidth * 0.7;
      const aspectRatio = 6 / 4;
      const cropHeight = cropWidth / aspectRatio;
      setCropSize({ width: cropWidth, height: cropHeight });
    }
  }, []);

  useEffect(() => {
    window.addEventListener('resize', updateCropSize);
    updateCropSize();

    return () => {
      window.removeEventListener('resize', updateCropSize);
    };
  }, [updateCropSize]);

  useEffect(() => {
    if (open) {
      setCrop({ x: 0, y: 0 });
      setZoom(1.5);
    }
  }, [open]);

  const onCropCompleteHandler = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleSave = useCallback(async () => {
    try {
      const croppedBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
      onCropComplete(croppedBlob);
      setOpen(false);
    } catch (e) {
      console.error(e);
    }
  }, [imageSrc, croppedAreaPixels, onCropComplete, setOpen]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        style: { backgroundColor: '#000' },
      }}
    >
      <AppBar
        position="relative"
        sx={{
          backgroundColor: '#222', // 深色背景，對比強烈
          paddingX: 2, // 增加左右內距，讓按鈕不擁擠
          boxShadow: 'none', // 移除陰影，讓 UI 更乾淨
        }}
      >
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {/* 左上角關閉按鈕 */}
          <Button
            onClick={handleClose}
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: '#fff',
              backgroundColor: '#444', // 深灰色區分「取消」
              padding: '8px 16px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#666',
              },
            }}
          >
            {t('common.cancel')}
          </Button>

          {/* 標題置中 */}
          <Typography variant="h6" sx={{ flex: 1, textAlign: 'center', color: '#fff' }}>
            {t('cropping.title')}
          </Typography>

          {/* 右上角完成按鈕 */}
          <Button
            onClick={handleSave}
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: '#fff',
              backgroundColor: '#007bff', // 藍色區分「確定」
              padding: '8px 16px',
              borderRadius: '8px',
              '&:hover': {
                backgroundColor: '#0056b3',
              },
            }}
          >
            {t('cropping.complete')}
          </Button>
        </Toolbar>
      </AppBar>
      <div ref={containerRefCallback} style={{ position: 'relative', width: '100%', height: '80vh', backgroundColor: '#333' }}>
        {cropSize && (
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={6 / 4}
            cropSize={cropSize}
            cropShape="rect"
            showGrid={false}
            onCropChange={setCrop}
            onCropComplete={onCropCompleteHandler}
            onZoomChange={setZoom}
            restrictPosition={false}
            minZoom={0.5}
            maxZoom={4}
            objectFit="contain"
          />
        )}
      </div>
      <Box sx={{ padding: '20px', backgroundColor: '#000', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '80%', maxWidth: 400 }}>
          <Typography variant="overline" style={{ color: '#fff', textAlign: 'center' }}>
            {t('cropping.zoom')}
          </Typography>
          <Slider
            value={zoom}
            min={0.5}
            max={4}
            step={0.005}
            onChange={(e, value) => setZoom(value)}
            sx={{
              color: '#fff',
              '& .MuiSlider-thumb': { color: '#fff', borderRadius: '50%', border: '2px solid #fff' },
              '& .MuiSlider-track': { color: '#fff' },
              '& .MuiSlider-rail': { color: '#888' },
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default CroppingModal;
