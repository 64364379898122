import React, { useState, useEffect, useRef } from 'react';
import { Box, IconButton, Switch, FormControlLabel } from '@mui/material';
import { PlayArrow, Pause } from '@mui/icons-material';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { keyframes } from '@mui/system';
import { Document, Page } from 'react-pdf';
import HTMLFlipBook from 'react-pageflip';
import { useTranslation } from 'react-i18next';
import languageStore from '../../stores/languageStore';

const FullScreenPdfAudioViewer = ({ storyId, onClose }) => {
  const { t } = useTranslation();
  const { language } = languageStore();
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPlayingDefault, setIsPlayingDefault] = useState(false);
  const [isPlayingEn, setIsPlayingEn] = useState(false);
  const [isAutoPlay, setIsAutoPlay] = useState(true);
  const [currentAudioFilename, setCurrentAudioFilename] = useState(null);
  const [isFullScreen, setIsFullScreen] = useState(true);
  const pdfUrl = `/assets/pdf/story_${storyId}.pdf`;
  const containerRef = useRef(null);
  const audioRef = useRef(new Audio());
  const bookRef = useRef(null);

  const PDF_WIDTH = 1440;
  const PDF_HEIGHT = 942;
  const winWidth = window.innerWidth;
  const winHeight = window.innerHeight;
  const scale = Math.min(winWidth / PDF_WIDTH, winHeight / PDF_HEIGHT);
  const scaledWidth = Math.round(PDF_WIDTH * scale * 0.97);
  const scaledHeight = Math.round(PDF_HEIGHT * scale * 0.97);

  const spinAnimation = keyframes`
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  `;

  useEffect(() => {
    if (containerRef.current && containerRef.current.requestFullscreen) {
      containerRef.current.requestFullscreen().catch(err => {
        console.warn("Fullscreen request failed", err);
      });
    }
  }, []);

  const playAudio = async (langKey) => {
    if (currentPage < 3 || currentPage >= numPages) return;
    const chapter = currentPage - 2;
    const lang = langKey === 'en' ? 'en' : 'default';
    const filename = `${storyId}_${chapter}_${lang}.mp3`;
    const audioUrl = `/assets/audio/${filename}`;

    if (
      audioRef.current.src &&
      audioRef.current.src.includes(audioUrl) &&
      audioRef.current.paused &&
      audioRef.current.currentTime > 0
    ) {
      try {
        await audioRef.current.play();
      } catch (error) {
        console.error("Audio play error", error);
      }
      if (lang === 'en') {
        setIsPlayingEn(true);
        setIsPlayingDefault(false);
      } else {
        setIsPlayingDefault(true);
        setIsPlayingEn(false);
      }
      return;
    }

    audioRef.current.src = audioUrl;
    try {
      await audioRef.current.play();
    } catch (error) {
      console.error("Audio play error", error);
    }
    setCurrentAudioFilename(filename);
    if (lang === 'en') {
      setIsPlayingEn(true);
      setIsPlayingDefault(false);
    } else {
      setIsPlayingDefault(true);
      setIsPlayingEn(false);
    }

    audioRef.current.onended = () => {
      setIsPlayingEn(false);
      setIsPlayingDefault(false);
      setCurrentAudioFilename(null);
    };
  };

  const togglePlayPause = (langKey) => {
    if (langKey === 'en') {
      if (isPlayingEn) {
        audioRef.current.pause();
        setIsPlayingEn(false);
      } else {
        playAudio('en');
      }
    } else {
      if (isPlayingDefault) {
        audioRef.current.pause();
        setIsPlayingDefault(false);
      } else {
        playAudio('default');
      }
    }
  };

  useEffect(() => {
    audioRef.current.pause();
    audioRef.current.src = "";
    setIsPlayingDefault(false);
    setIsPlayingEn(false);
    if (isAutoPlay && currentPage >= 3 && currentPage < numPages) {
      const timeout = setTimeout(() => {
        playAudio(language);
      }, 1500);
      return () => clearTimeout(timeout);
    }
  }, [currentPage, isAutoPlay, numPages]);

  useEffect(() => {
    const handleExitFullScreen = () => {
      const fsEl =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement;

      if (!fsEl) {
        console.log('🔴 Fullscreen exited');
        setIsFullScreen(false);
        onClose(); // ✅ 關閉 PDF Viewer
        // ✅ 停止音訊並清除
        audioRef.current.currentTime = 0;
        audioRef.current.pause();
        audioRef.current.src = "";
        setIsPlayingDefault(false);
        setIsPlayingEn(false);
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        console.log('🔴 ESC pressed, exiting fullscreen');
        handleExitFullScreen();
      }
    };

    document.addEventListener('fullscreenchange', handleExitFullScreen);
    document.addEventListener('webkitfullscreenchange', handleExitFullScreen);
    document.addEventListener('mozfullscreenchange', handleExitFullScreen);
    document.addEventListener('MSFullscreenChange', handleExitFullScreen);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('fullscreenchange', handleExitFullScreen);
      document.removeEventListener('webkitfullscreenchange', handleExitFullScreen);
      document.removeEventListener('mozfullscreenchange', handleExitFullScreen);
      document.removeEventListener('MSFullscreenChange', handleExitFullScreen);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div ref={containerRef} className={document.fullscreenElement ? "" : "fullscreen-container"}>
      <div>
        {numPages && currentPage >= 3 && currentPage < numPages && (
          <Box
            sx={{
              position: "absolute",
              bottom: { xs: "15%", sm: "25%" },
              left: "50%",
              transform: "translateX(-50%)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 10,
              bgcolor: "rgba(0, 0, 0, 0.7)",
              px: { xs: 0.1, sm: 0.2 },
              py: { xs: 0.1, sm: 0.1 },
              borderRadius: 20,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
              width: "fit-content",
              fontSize: { xs: "0.3rem", sm: "0.4rem", md: "0.5rem" }
            }}
          >
            <IconButton onClick={() => togglePlayPause("default")} sx={{ color: isPlayingDefault ? "#00E676" : "#FFFFFF", mx: 0.3 }}>
              {isPlayingDefault ? <Pause /> : <PlayArrow />}
              <span style={{ marginLeft: 4 }}>{t("lang_full_name")}</span>
            </IconButton>
            <IconButton onClick={() => togglePlayPause("en")} sx={{ color: isPlayingEn ? "#FFEA00" : "#FFFFFF", mx: 0.3 }}>
              {isPlayingEn ? <Pause /> : <PlayArrow />}
              <span style={{ marginLeft: 4 }}>English</span>
            </IconButton>
            <IconButton onClick={onClose} sx={{ color: "#FFFFFF", ml: 2 }}>關閉</IconButton>
          </Box>
        )}
        <Document file={pdfUrl} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
          {numPages !== null && (
            <HTMLFlipBook width={scaledWidth} height={scaledHeight} size="fixed" showCover={false} usePortrait={true} flippingTime={1000} drawShadow={true} autoSize={false} mobileScrollSupport={true} ref={bookRef} onFlip={(e) => setCurrentPage(e.data + 1)}>
              {Array.from({ length: numPages }, (_, index) => (
                <div key={index}>
                  <Page pageNumber={index + 1} width={scaledWidth} height={scaledHeight} />
                </div>
              ))}
            </HTMLFlipBook>
          )}
        </Document>
      </div>
    </div>
  );
};

export default FullScreenPdfAudioViewer;
