import React, { useState, useEffect, useRef } from 'react'
import { Box, Pagination, IconButton, Switch, FormControlLabel } from '@mui/material'
import { PlayArrow, Pause } from '@mui/icons-material'
import { keyframes } from '@mui/system'
import AutorenewIcon from '@mui/icons-material/Autorenew'
import CompleteCategorySection from './utils/CompleteCategorySection'
import ShareSection from './utils/ShareSection'
import { Document, Page } from 'react-pdf'
import HTMLFlipBook from 'react-pageflip'
import api from '../../services/apiService'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next'
import languageStore from '../../stores/languageStore';

function useWindowSize() {
  const [size, setSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })
  useEffect(() => {
    const onResize = () => {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }
    window.addEventListener('resize', onResize)
    return () => window.removeEventListener('resize', onResize)
  }, [])
  return size
}

const StoryListViewer = () => {
  const { t } = useTranslation()
  const { language } = languageStore(); // 獲取當前語言
  const [completedStories, setCompletedStories] = useState([])
  const [loading, setLoading] = useState(true)
  const [webViewpdfUrl, setWebViewpdfUrl] = useState('')
  const [numPages, setNumPages] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentListPage, setCurrentListPage] = useState(1)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [shareOpen, setShareOpen] = useState(false)
  const [shareTitle, setShareTitle] = useState('')
  const [shareCode, setShareCode] = useState('')
  const [isPlayingDefault, setIsPlayingDefault] = useState(false) // 是否正在播放中文
  const [isPlayingEn, setIsPlayingEn] = useState(false) // 是否正在播放英文
  const audioRef = useRef(new Audio()) // 音訊播放器
  const bookRef = useRef();
  const containerRef = useRef(null)
  const itemsPerPage = 5
  const { width: winWidth, height: winHeight } = useWindowSize()
  const [selectedStoryId, setSelectedStoryId] = useState(null)
  const [selectedStoryStatus, setSelectedStoryStatus] = useState(null)
  const [currentAudioFilename, setCurrentAudioFilename] = useState(null);
  const [isAutoPlay, setIsAutoPlay] = useState(true); // 🔹 自動播放開關

  const PDF_WIDTH = 1440
  const PDF_HEIGHT = 942

  const scaleWidth = (isFullScreen ? winWidth : winWidth) / PDF_WIDTH
  const scaleHeight = (isFullScreen ? winHeight : winHeight) / PDF_HEIGHT
  const scale = Math.min(scaleWidth, scaleHeight)
  const scaledWidth = Math.round(PDF_WIDTH * scale * 0.96)
  const scaledHeight = Math.round(PDF_HEIGHT * scale * 0.96)

  const loadFile = async (filename) => {
    if (!filename) return ''
    try {
      const response = await api.get(`/api/stories/getfile/list/${filename}`, {
        responseType: 'blob'
      })
      return URL.createObjectURL(response.data)
    } catch (error) {
      console.error(t('error.loadFileFailed'), error)
      return ''
    }
  }

  const fetchCompletedStories = async () => {
    try {
      const response = await api.get('/api/stories/getCompletedStories');
      const stories = response.data;

      const storiesWithAssets = await Promise.all(
        stories.map(async (story) => {
          return {
            ...story,
            coverImageUrl: await loadFile(story.coverImage),
            pdfFile: story.pdfFile,
            webViewPdfFile: story.pdfFile,
            storyId: story.storyId,
            status: story.status
          };
        })
      );

      setCompletedStories(storiesWithAssets);
    } catch (error) {
      console.error(t('error.fetchStoriesFailed'), error);
    } finally {
      setLoading(false);
    }
  };

  const playAudio = async (langKey) => {
    console.log('currentPage', currentPage);
    if (!selectedStoryId || !currentPage || selectedStoryStatus != 'paid') return;

    var page = currentPage - 2;
    var lang = langKey;
    if (lang !== 'en') {
      lang = 'default';
    }
    const filename = `${selectedStoryId}_${page}_${lang}.mp3`;

    // 如果目前的音檔已經是正確的，就直接繼續播放
    if (audioRef.current.src && currentAudioFilename === filename && audioRef.current.paused &&
      audioRef.current.currentTime > 0) {
      console.log('pause and play');

      try {
        await audioRef.current.play().catch((error) => {
          console.error("音訊播放失敗:", error);
        });
      } catch (error) {
        console.error("音訊播放異常:", error);
      }

      if (langKey === 'en') {
        setIsPlayingEn(true);
        setIsPlayingDefault(false);
      } else {
        setIsPlayingDefault(true);
        setIsPlayingEn(false);
      }
      return;
    }

    try {
      const response = await api.get(`/api/stories/getfile/audio/${filename}`, {
        responseType: 'blob'
      });

      const audioUrl = URL.createObjectURL(response.data);
      audioRef.current.src = audioUrl;
      try {
        await audioRef.current.play().catch((error) => {
          console.error("音訊播放失敗:", error);
        });
      } catch (error) {
        console.error("音訊播放異常:", error);
      }
      setCurrentAudioFilename(filename);

      if (langKey === 'en') {
        setIsPlayingEn(true);
        setIsPlayingDefault(false);
      } else {
        setIsPlayingDefault(true);
        setIsPlayingEn(false);
      }

      audioRef.current.onended = () => {
        setIsPlayingEn(false);
        setIsPlayingDefault(false);
        setCurrentAudioFilename(null);
      };
    } catch (error) {
      console.error(`Error loading ${langKey} audio:`, error);
      Swal.fire({
        title: t('common.error'),
        text: t('error.audioLoadError'),
        icon: 'error',
        confirmButtonText: t('common.ok')
      });
    }
  };

  const togglePlayPause = (langKey) => {
    if (!isFullScreen) return;
    if (langKey === 'en') {
      if (isPlayingEn) {
        audioRef.current.pause();
        setIsPlayingEn(false);
      } else {
        playAudio('en');
      }
    } else {
      if (isPlayingDefault) {
        audioRef.current.pause();
        setIsPlayingDefault(false);
      } else {
        playAudio('default');
      }
    }
  };

  useEffect(() => {
    fetchCompletedStories()
  }, [])

  useEffect(() => {
    audioRef.current.currentTime = 0;
    audioRef.current.pause(); // 暫停播放
    audioRef.current.src = ""; // 清除音源，避免下一次繼續播放
    setIsPlayingDefault(false);
    setIsPlayingEn(false);
    if (isFullScreen && isAutoPlay && currentPage >= 3 && currentPage < 15 && selectedStoryStatus == 'paid') {
      const timeout = setTimeout(() => {
        playAudio(language); // 延遲 1.5 秒後播放
      }, 1500);

      return () => clearTimeout(timeout); // 清除計時器，避免重複執行
    }

  }, [currentPage, isFullScreen, isAutoPlay]);

  const handleImageClick = async (status, storyId, webViewPdfFile) => {
    try {
      const url = await loadFile(webViewPdfFile);
      setWebViewpdfUrl(url);
      setIsFullScreen(true);
      setSelectedStoryId(storyId);
      setSelectedStoryStatus(status);
      setCurrentPage(1);
      setNumPages(null);

      if (containerRef.current) {
        let fullscreenRequested = false;

        if (containerRef.current.requestFullscreen) {
          containerRef.current.requestFullscreen().then(() => {
            fullscreenRequested = true;
          }).catch(err => {
            console.warn("Fullscreen API error:", err);
          });
        } else if (containerRef.current.mozRequestFullScreen) {
          containerRef.current.mozRequestFullScreen();
          fullscreenRequested = true;
        } else if (containerRef.current.webkitRequestFullscreen) {
          containerRef.current.webkitRequestFullscreen();
          fullscreenRequested = true;
        } else if (containerRef.current.msRequestFullscreen) {
          containerRef.current.msRequestFullscreen();
          fullscreenRequested = true;
        }

        // 🔹 等 1 秒後確認是否真的進入了全螢幕
        setTimeout(() => {
          if (!document.fullscreenElement) {
            console.warn("🔴 Fullscreen request failed, using fullscreen-container");
            setIsFullScreen(true);  // ✅ 只有當 requestFullscreen 失敗時才使用 fullscreen-container
          }
        }, 1000);
      }
    } catch (error) {
      Swal.fire(t('error.pdfLoadError'), error.message, 'error');
    }
  };

  const handleDownloadPdfClick = async (title, pdfFile) => {
    try {
      const url = await loadFile(pdfFile)
      const link = document.createElement('a')
      link.href = url
      link.download = `${title}.pdf`
      link.click()
    } catch (error) {
      console.error(t('error.downloadFailed'), error)
    }
  }

  const handleBuyClick = async (storyListId) => {
    try {
      const result = await Swal.fire({
        title: t('confirm.paymentTitle'),
        text: t('confirm.paymentText'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('common.confirm'),
        cancelButtonText: t('common.cancel')
      })
      if (result.isConfirmed) {
        const response = await api.post('/api/stories/confirmPayment', { storyListId })
        const { pdfFilename } = response.data
        Swal.fire(t('payment.success'), t('payment.successMessage'), 'success')
        await fetchCompletedStories();
      }
    } catch (error) {
      Swal.fire(t('payment.failure'), t('payment.failureMessage'), 'error')
    }
  }

  const handleShareClick = (title, code) => {
    setShareTitle(title)
    setShareCode(code)
    setShareOpen(true)
  }

  const spinAnimation = keyframes`
  0% { transform: rotate(0deg) }
  100% { transform: rotate(360deg) }
`

  useEffect(() => {
    const handleExitFullScreen = () => {
      const fsEl =
        document.fullscreenElement ||
        document.webkitFullscreenElement ||
        document.mozFullScreenElement ||
        document.msFullscreenElement;

      if (!fsEl) {
        console.log('🔴 Fullscreen exited');
        setIsFullScreen(false);
        // 停止音訊並清除
        audioRef.current.currentTime = 0;
        audioRef.current.pause();
        audioRef.current.src = "";
        setIsPlayingDefault(false);
        setIsPlayingEn(false);
        /*setTimeout(() => {
          if (selectedStoryId) {
            console.log('selectedStoryId:', selectedStoryId);
            const element = document.getElementById(`story-${selectedStoryId}`);
            if (element) {
              console.log('滾動回:', element);
              element.scrollIntoView({ behavior: 'smooth', block: 'center' });
            } else {
              console.error('找不到對應的故事區塊:', `story-${selectedStoryId}`);
            }
          }
        }, 500); // 延遲確保 DOM 回到原狀*/
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        console.log('🔴 ESC pressed, exiting fullscreen');
        handleExitFullScreen();
      }
    };

    document.addEventListener('fullscreenchange', handleExitFullScreen);
    document.addEventListener('webkitfullscreenchange', handleExitFullScreen);
    document.addEventListener('mozfullscreenchange', handleExitFullScreen);
    document.addEventListener('MSFullscreenChange', handleExitFullScreen);
    document.addEventListener('keydown', handleKeyDown); // 監聽 ESC 鍵

    return () => {
      document.removeEventListener('fullscreenchange', handleExitFullScreen);
      document.removeEventListener('webkitfullscreenchange', handleExitFullScreen);
      document.removeEventListener('mozfullscreenchange', handleExitFullScreen);
      document.removeEventListener('MSFullscreenChange', handleExitFullScreen);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedStoryId]);

  // **計算當前頁面應該顯示的故事**
  const indexOfLastItem = currentListPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentStories = completedStories.slice(indexOfFirstItem, indexOfLastItem);

  return (
    <div className="container mx-auto p-6">
      {loading ? (
        <p className="text-center text-gray-500">{t('common.loading')}</p>
      ) : (
        <div>
          {currentStories.map((story) => (
            <CompleteCategorySection
              key={story.id}
              id={story.id}
              title={story.title}
              description={story.description}
              books={[{ imgSrc: story.coverImageUrl, title: story.title }]}
              price={story.status === 'paid' ? null : story.price}
              isPaid={story.status === 'paid'}
              updatedAt={story.updated_at}
              onDownloadPdfClick={() => handleDownloadPdfClick(story.title, story.pdfFile)}
              onImageClick={() => handleImageClick(story.status, story.id, story.webViewPdfFile)}
              onBuyClick={() => handleBuyClick(story.id)}
              onShareClick={() => handleShareClick(story.title, story.shareCode)}
            />
          ))}

          <Box display="flex" justifyContent="center" alignItems="center" mb={8}>
            <Pagination
              count={Math.ceil(completedStories.length / itemsPerPage)}
              page={currentListPage}
              onChange={(event, value) => {
                if (value) setCurrentListPage(value);
              }}
              variant="outlined"
              shape="rounded"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#ffffff',
                  borderColor: '#bdbdbd',
                  fontWeight: 'bold',
                  transition: 'all 0.2s ease-in-out',
                },
                '& .MuiPaginationItem-root.Mui-selected': { // 這裡改用更精確的選擇器
                  backgroundColor: '#1976d2',
                  color: '#ffffff',
                  borderColor: '#1565c0',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#1565c0',
                  },
                },
                '& .MuiPaginationItem-root:hover': {
                  backgroundColor: '#424242',
                },
              }}
            />
          </Box>

          {/* PDF 文件顯示 */}
          {isFullScreen && webViewpdfUrl && (
            <div ref={containerRef} className={document.fullscreenElement ? "" : "fullscreen-container"}>
              <div>
                {/* 右上角播放/暫停按鈕 */}
                {selectedStoryStatus == 'paid' && currentPage >= 3 && currentPage < 15 && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: { xs: "10%", sm: "20%" },
                      left: "50%",
                      transform: "translateX(-50%)",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      zIndex: 10,
                      bgcolor: "rgba(0, 0, 0, 0.7)",
                      px: { xs: 0.1, sm: 0.2 },
                      py: { xs: 0.1, sm: 0.1 },
                      borderRadius: 20,
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.3)",
                      width: "fit-content",
                      fontSize: { xs: "0.3rem", sm: "0.4rem", md: "0.5rem" }
                    }}
                  >
                    {language === "en" ? (
                      <IconButton
                        onClick={() => togglePlayPause("en")}
                        sx={{
                          color: isPlayingEn ? "#FFEA00" : "#FFFFFF",
                          mx: 0.3,
                          "&:hover": { opacity: 0.8 },
                          fontSize: { xs: "0.6rem", sm: "0.8rem", md: "1rem" }
                        }}
                      >
                        {isPlayingEn ? <Pause fontSize="inherit" /> : <PlayArrow fontSize="inherit" />}
                        <span style={{ marginLeft: 2, fontSize: "inherit" }}>English</span>
                      </IconButton>
                    ) : (
                      <>
                        <IconButton
                          onClick={() => togglePlayPause("default")}
                          sx={{
                            color: isPlayingDefault ? "#00E676" : "#FFFFFF",
                            mx: 0.3,
                            "&:hover": { opacity: 0.8 },
                            fontSize: { xs: "0.6rem", sm: "0.8rem", md: "1rem" }
                          }}
                        >
                          {isPlayingDefault ? <Pause fontSize="inherit" /> : <PlayArrow fontSize="inherit" />}
                          <span style={{ marginLeft: 2, fontSize: "inherit" }}>{t("lang_full_name")}</span>
                        </IconButton>
                        <IconButton
                          onClick={() => togglePlayPause("en")}
                          sx={{
                            color: isPlayingEn ? "#FFEA00" : "#FFFFFF",
                            mx: 0.3,
                            "&:hover": { opacity: 0.8 },
                            fontSize: { xs: "0.6rem", sm: "0.8rem", md: "1rem" }
                          }}
                        >
                          {isPlayingEn ? <Pause fontSize="inherit" /> : <PlayArrow fontSize="inherit" />}
                          <span style={{ marginLeft: 2, fontSize: "inherit" }}>English</span>
                        </IconButton>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={isAutoPlay}
                              onChange={() => setIsAutoPlay(!isAutoPlay)}
                              sx={{
                                "& .MuiSwitch-switchBase.Mui-checked": { color: "#00E676" },
                                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": { backgroundColor: "#00E676" }
                              }}
                            />
                          }
                          label={t("common.autoPlay")}
                          TypographyProps={{
                            sx: {
                              fontSize: { xs: "0.2rem", sm: "0.3rem", md: "0.4rem" }
                            }
                          }}
                          sx={{
                            color: "#FFFFFF",
                            ml: 0.3
                          }}
                        />
                      </>
                    )}
                  </Box>
                )}
                <Document
                  file={webViewpdfUrl}
                  onLoadSuccess={({ numPages }) => {
                    setTimeout(() => {
                      setNumPages(numPages);  // ✅ 確保 PDF 加載成功時，更新頁數
                    }, 1500);
                    setCurrentPage(1);  // ✅ 讓書籍從第一頁開始
                    console.log(`PDF 總頁數: ${numPages}`);
                    console.log(`scale: ${scale}`);
                    console.log(`winWidth: ${winWidth} ${winHeight}`);
                    console.log(`scaledWidth: ${scaledWidth} ${scaledHeight}`);
                  }}
                >
                  {numPages ? (
                    <div className="flipbook-container">
                      <HTMLFlipBook
                        width={scaledWidth}
                        height={scaledHeight}
                        size="fixed"
                        className="mx-auto"
                        showCover={false}
                        usePortrait={true}
                        flippingTime={1000}
                        drawShadow={true}
                        autoSize={true}
                        mobileScrollSupport={true}
                        ref={bookRef}
                        onFlip={(e) => setCurrentPage(e.data + 1)}
                      >
                        {Array.from({ length: numPages }, (_, index) => (
                          <div key={index}>
                            <Page
                              pageNumber={index + 1}
                              width={scaledWidth}
                              height={scaledHeight}
                            />
                          </div>
                        ))}
                      </HTMLFlipBook>
                    </div>

                  ) : (
                    <div className="loading-container">
                      <AutorenewIcon
                        sx={{
                          fontSize: 40,
                          animation: `${spinAnimation} 1s linear infinite`
                        }}
                      />
                    </div>
                  )}
                </Document>
              </div>
            </div>
          )}

          <ShareSection
            shareTitle={shareTitle}
            shareCode={shareCode}
            open={shareOpen}
            onClose={() => setShareOpen(false)}
          />
        </div>
      )}
    </div>
  )
}

export default StoryListViewer
