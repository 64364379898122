import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import HTMLFlipBook from 'react-pageflip';
import StepNavigation from './utils/StepNavigation';
import api from '../../services/apiService';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

const Step6_bookViewer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef();
  const bookRef = useRef();
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [pdfUrl, setPdfUrl] = useState('');
  const [watermarkedPdfUrl, setWatermarkedPdfUrl] = useState('');
  const [storyCompleted, setStoryCompleted] = useState(false);
  const [bookPrice, setBookPrice] = useState(null);
  const [isPaid, setIsPaid] = useState(false);

  useEffect(() => {
    fetchSelectedImages();
  }, []);

  const fetchSelectedImages = async () => {
    try {
      const storyListId = localStorage.getItem('storyListId');
      if (!storyListId) {
        throw new Error(t('error.missingStoryListId'));
      }

      const response = await api.get('/api/stories/getSelectedImages', {
        params: { storyListId },
      });

      const filenames = response.data;
      const imageUrls = [];

      for (const file of filenames) {
        const { selected_image_filename } = file;

        const blobResponse = await api.get(`/api/stories/getfile/image/${selected_image_filename}`, {
          responseType: 'blob',
        });

        const imageUrl = URL.createObjectURL(blobResponse.data);
        imageUrls.push(imageUrl);
      }

      setImages(imageUrls);

    } catch (error) {
      console.error(t('error.fetchImagesFailed'), error);

      // 顯示錯誤彈窗，提供重新整理的選項
      Swal.fire({
        title: t('common.error'),
        text: t('error.imageLoadError'),
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: t('common.refresh'), // 重新整理
        cancelButtonText: t('common.cancel'), // 取消
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(); // 使用者選擇重新整理才刷新頁面
        }
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchPdfFile = async (pdfFilename, setPdfUrl) => {
    try {
      const response = await api.get(`/api/stories/getfile/list/${pdfFilename}`, {
        responseType: 'blob',
      });
      const blobUrl = URL.createObjectURL(response.data);
      setPdfUrl(blobUrl);
    } catch (error) {
      console.error(t('error.fetchPdfFailed'), error);
    }
  };

  const handleConfirmCompletion = async () => {
    try {
      const storyListId = localStorage.getItem('storyListId');
      if (!storyListId) {
        throw new Error(t('error.missingStoryListId'));
      }

      const response = await api.post('/api/stories/confirmCompletion', { storyListId });
      const { watermarkedPdfFilename, bookPrice } = response.data;

      fetchPdfFile(watermarkedPdfFilename, setWatermarkedPdfUrl);

      localStorage.removeItem('selectedCategory');
      localStorage.removeItem('selectedTitle');
      localStorage.removeItem('storyData');

      setBookPrice(bookPrice);
      setStoryCompleted(true);
    } catch (error) {
      console.error(t('error.confirmCompletionFailed'), error);
      Swal.fire({
        title: t('common.error'),
        text: t('error.confirmCompletionError'),
        icon: 'error',
        confirmButtonText: t('common.ok'),
      });
    }
  };

  const handleConfirmButtonClick = async () => {
    const result = await Swal.fire({
      title: t('confirm.completionTitle'),
      text: t('confirm.completionWarn'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('common.confirm'),
      cancelButtonText: t('common.cancel'),
    });

    if (result.isConfirmed) {
      await handleConfirmCompletion();
    }
  };

  const handleGoToMyStoryList = () => {
    navigate('/create-story/mystorylist');
  };

  const confirmPayment = async () => {
    const result = await Swal.fire({
      title: t('confirm.paymentTitle'),
      text: t('confirm.paymentText'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('common.confirm'),
      cancelButtonText: t('common.cancel'),
    });

    if (result.isConfirmed) {
      try {
        const storyListId = localStorage.getItem('storyListId');
        if (!storyListId) {
          throw new Error(t('error.missingStoryListId'));
        }

        const response = await api.post('/api/stories/confirmPayment', { storyListId });
        const { pdfFilename } = response.data;

        fetchPdfFile(pdfFilename, setPdfUrl);

        Swal.fire({
          title: t('payment.success'),
          text: t('payment.successMessage'),
          icon: 'success',
          confirmButtonText: t('common.ok'),
        });

        setIsPaid(true);
        //fetchSelectedImages();
      } catch (error) {
        console.error(t('error.paymentFailed'), error);
        Swal.fire({
          title: t('common.error'),
          text: t('payment.failureMessage'),
          icon: 'error',
          confirmButtonText: t('common.ok'),
        });
      }
    }
  };

  const toggleFullScreen = () => {
    if (containerRef.current) {
      if (!document.fullscreenElement) {
        containerRef.current.requestFullscreen().catch((err) => {
          console.error(t('error.fullscreenError'), err.message);
        });
      } else {
        document.exitFullscreen();
      }
    }
  };

  const exitFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.error(t('error.exitFullscreenError'), err.message);
      });
    }
  };

  return (
    <div className="container mx-auto p-6">
      <div className="flex items-start justify-center mb-8 relative">
        <div className="flex items-start space-x-8">
          <button
            className="bg-gray-500 text-white h-12 w-32 rounded-full text-lg flex items-center justify-center"
            onClick={() => navigate('/create-story/step5-picView')}
          >
            {t('common.previous')}
          </button>
          <StepNavigation currentStep={6} />
        </div>
      </div>

      {bookPrice && (
        <div className="flex justify-center mb-4">
          <span className="text-lg text-gray-300">{`${t('complete.bookPriceMessage')} ${bookPrice} USD`}</span>
        </div>
      )}

      {!storyCompleted && (
        <div className="flex justify-center mb-8">
          <button
            className="bg-orange-500 text-white py-2 px-6 rounded-full text-lg"
            onClick={handleConfirmButtonClick}
          >
            {t('complete.confirmCompletion')}
          </button>
        </div>
      )}

      <div className="flex justify-center mb-8">
        {storyCompleted && !isPaid && (
          <button
            className="bg-green-500 text-white py-2 px-6 rounded-full text-lg"
            onClick={confirmPayment}
          >
            {t('payment.confirmPurchase')}
          </button>
        )}
      </div>

      {storyCompleted && (
        <div className="flex justify-center mb-8">
          <button
            className="bg-blue-500 text-white py-2 px-6 rounded-full text-lg"
            onClick={handleGoToMyStoryList}
          >
            {t('complete.goToMyStory')}
          </button>
        </div>
      )}

      {loading ? (
        <p className="text-center text-gray-500">{t('common.loading')}</p>
      ) : (
        !storyCompleted && ( // 只有在未確認前才顯示
          <div ref={containerRef} className="flex justify-center mb-8">
            {document.fullscreenElement && (
              <button
                className="bg-red-500 text-white py-2 px-6 rounded-full text-lg absolute top-4 right-4"
                onClick={exitFullScreen}
              >
                {t('step6_bookViewer.exitFullScreen')}
              </button>
            )}
            <HTMLFlipBook
              width={720}
              height={942}
              size="stretch"
              drawShadow={true}
              flippingTime={1000}
              usePortrait={true}
              startZIndex={0}
              autoSize={true}
              maxShadowOpacity={0.5}
              showCover={true}
              mobileScrollSupport={true}
              ref={bookRef}
            >
              {images.map((imageUrl, index) => (
                <div key={index} className="demoPage">
                  <img
                    src={imageUrl}
                    alt={`${t('step6_bookViewer.page')} ${index + 1}`}
                    className="w-full h-auto"
                  />
                </div>
              ))}
            </HTMLFlipBook>
          </div>
        )
      )}

      {!storyCompleted && (
        <div className="flex justify-center mb-8">
          <button
            className="bg-blue-500 text-white py-2 px-6 rounded-full text-lg"
            onClick={toggleFullScreen}
          >
            {t('step6_bookViewer.toggleFullScreen')}
          </button>
        </div>
      )}
    </div>
  );
};

export default Step6_bookViewer;
