import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import HTMLFlipBook from 'react-pageflip';
import FullScreenPdfAudioViewer from './FullScreenPdfAudioViewer';

const Home = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const bookRef = useRef();
  const [selectedStoryId, setSelectedStoryId] = useState(null);

  const handleStartStoryCreation = () => {
    navigate('/create-story');
  };

  const flipBookStoryId = "92";

  const images = [];

  images.push("/assets/home-1/1.jpg");

  for (let i = 2; i <= 15; i++) {
    images.push(`/assets/home-1/${i}-1.jpg`);
    images.push(`/assets/home-1/${i}-2.jpg`);
  }

  images.push("/assets/home-1/16.jpg");

  return (
    <div className="container mx-auto px-4 py-20 text-gray-300">
      {/* Hero Section */}
      <div className="flex flex-col items-center md:flex-row md:items-start mb-12">
        <HTMLFlipBook
          width={400}
          height={524}
          startPage={7}
          size="stretch"
          drawShadow={true}
          showCover={true}
          flippingTime={1000}
          usePortrait={true}
          startZIndex={0}
          autoSize={true}
          maxShadowOpacity={0.5}
          mobileScrollSupport={true}
          ref={bookRef}
        >
          {images.map((src, index) => (
            <div key={index} className="demoPage">
              <img src={src} alt={`Page ${index + 1}`} className="w-full h-auto" />
            </div>
          ))}
        </HTMLFlipBook>
        <div className="flex flex-col items-center md:ml-8 mt-4 md:mt-0">
          <img
            src="/assets/ci_92.jpg"
            className="rounded-lg shadow-lg mb-4"
            style={{
              width: '200px',
              height: '200px',
              objectFit: 'cover'
            }}
          />
          <button
            className="bg-blue-500 text-white font-bold py-2 px-4 rounded-lg mb-4"
            onClick={() => setSelectedStoryId(flipBookStoryId)}
          >
            {t('home.fullscreenStory')}
          </button>
          <button
            className="bg-yellow-500 text-gray-800 font-bold py-2 px-4 rounded-lg"
            onClick={handleStartStoryCreation}
          >
            {t('home.startCreatingStory')}
          </button>
        </div>
      </div>

      {/* Adventure Section */}
      <div className="mb-12">
        <div className="flex flex-col md:flex-row items-center">
          <div
            className="bg-gray-800 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform cursor-pointer"
            onClick={() => setSelectedStoryId("28")}
          >
            <img
              src="/assets/ci_28.jpg"
              alt={t('home.adventure.alt')}
              className="w-[250px] h-auto object-contain rounded"
            />
          </div>
          <div className="flex flex-col items-start md:ml-8 mt-4 md:mt-0 text-gray-100">
            <h2 className="text-3xl font-bold">{t('home.adventure.title')}</h2>
            <p className="text-md text-gray-400 mt-2 mb-4">{t('home.adventure.description')}</p>
            <p className="text-md text-gray-400 mt-2">{t('home.adventure.price')}</p>
            <p className="text-md text-gray-400 mt-2">{t('home.adventure.summary')}</p>
            <ul className="list-disc list-inside text-md text-gray-400 mt-2">
              <li>{t('home.adventure.story1')}</li>
              <li>{t('home.adventure.story2')}</li>
              <li>{t('home.adventure.story3')}</li>
              <li>{t('home.adventure.story4')}</li>
              <li>{t('home.adventure.story5')}</li>
            </ul>
            <button
              className="bg-yellow-500 text-gray-800 font-bold py-2 px-4 rounded-lg mt-4"
              onClick={handleStartStoryCreation}
            >
              {t('home.startCreatingStory')}
            </button>
          </div>
        </div>
      </div>

      {/* Education Section */}
      <div className="mb-12">
        <div className="flex flex-col md:flex-row items-center">
          <div
            className="bg-gray-800 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform cursor-pointer"
            onClick={() => setSelectedStoryId("29")}
          >
            <img
              src="/assets/ci_29.jpg"
              alt={t('home.education.alt')}
              className="w-[250px] h-auto object-contain rounded"
            />
          </div>
          <div className="flex flex-col items-start md:ml-8 mt-4 md:mt-0 text-gray-100">
            <h2 className="text-3xl font-bold">{t('home.education.title')}</h2>
            <p className="text-md text-gray-400 mt-2 mb-4">{t('home.education.description')}</p>
            <p className="text-md text-gray-400 mt-2">{t('home.education.price')}</p>
            <p className="text-md text-gray-400 mt-2">{t('home.education.summary')}</p>
            <ul className="list-disc list-inside text-md text-gray-400 mt-2">
              <li>{t('home.education.story1')}</li>
              <li>{t('home.education.story2')}</li>
              <li>{t('home.education.story3')}</li>
              <li>{t('home.education.story4')}</li>
              <li>{t('home.education.story5')}</li>
            </ul>
            <button
              className="bg-yellow-500 text-gray-800 font-bold py-2 px-4 rounded-lg mt-4"
              onClick={handleStartStoryCreation}
            >
              {t('home.startCreatingStory')}
            </button>
          </div>
        </div>
      </div>

      {/* Book Showcase */}
      <div className="text-center mb-12">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-12">
          {[
            "30", "36", "38", "39", "40", "41", "42", "46"
          ].map((storyId, index) => (
            <div
              key={index}
              className="bg-gray-800 p-6 rounded-lg shadow-lg transform hover:scale-105 transition-transform cursor-pointer"
              onClick={() => setSelectedStoryId(storyId)}
            >
              <img src={`/assets/ci_${storyId}.jpg`} alt={`Story ${storyId}`} className="w-400 h-400 object-cover rounded mb-4" />
            </div>
          ))}
        </div>
      </div>

      {/* PDF Viewer */}
      {selectedStoryId && (
        <FullScreenPdfAudioViewer storyId={selectedStoryId} onClose={() => setSelectedStoryId(null)} />
      )}
    </div>
  );
};

export default Home;
