import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // 引入 useTranslation 用於語言化
import StepNavigation from './utils/StepNavigation';
import api from '../../services/apiService';
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2';
import { Pagination } from '@mui/material';
import '../css/ImageSelector.css';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const Step5_picView = () => {
  const { t } = useTranslation(); // 使用 useTranslation 進行語言化
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [storyData, setStoryData] = useState(null);
  const [storyContent, setStoryContent] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageFilenames, setImageFilenames] = useState([]); // 儲存多張圖片檔案名
  const [currentImageIndex, setCurrentImageIndex] = useState(0); // 當前顯示的大圖索引
  const [generatedImageUri, setGeneratedImageUri] = useState('');
  const [loading, setLoading] = useState(false);
  const [quotaChecked, setQuotaChecked] = useState(false); // 新增狀態以確保免費額度已檢查
  const isMounted = useRef(true);
  const intervalRef = useRef(null); // 用來存儲 interval 的 ID

  useEffect(() => {
    const checkQuota = async () => {
      try {
        const response = await api.get('/api/balance/checkFreeQuota');
        const { result, title, reason } = response.data;

        if (!result) {
          await Swal.fire({
            icon: 'warning',
            title, // 使用後端返回的 title
            text: reason, // 使用後端返回的 reason
            confirmButtonText: t('common.confirm'),
          });
          navigate('/create-story/step4-storyList'); // 檢查不通過則返回上一頁
        } else {
          setQuotaChecked(true); // 通過檢查後設定為 true
        }
      } catch (error) {
        console.error('檢查免費額度失敗', error);
      }
    };

    checkQuota();
  }, [navigate]);

  useEffect(() => {
    const savedStoryData = JSON.parse(localStorage.getItem('storyData'));

    if (savedStoryData) {
      savedStoryData.storyChapters = savedStoryData.storyChapters.filter(
        (chapter) => chapter.chapter_number !== 1000
      );
      setStoryData(savedStoryData);
      setCurrentPage(savedStoryData.storyChapters[0]?.chapter_number || 0);
    }

    return () => {
      isMounted.current = false;
    };
  }, []);

  // 僅在免費額度檢查通過且有需要生成圖片時執行
  useEffect(() => {
    if (quotaChecked && storyData && currentPage !== null) {
      const selectedChapter = storyData.storyChapters.find(
        (chapter) => chapter.chapter_number === currentPage
      );
      if (selectedChapter) {
        var desc = selectedChapter.description || '';
        console.log('selectedChapter', selectedChapter)
        if (selectedChapter.chapter_number <= 0 ||
          selectedChapter.chapter_number >= 998
        ) {
          desc = t('step5_picView.scenarioDescription') + desc;
        }
        setStoryContent(desc);
        setImageFilenames(selectedChapter.imageFilenames || []); // 設置多張圖片
        setSelectedImage(
          selectedChapter.selectedImage !== undefined ? selectedChapter.selectedImage : null
        );
      }
    }
  }, [quotaChecked, currentPage, storyData]);

  useEffect(() => {
    if (quotaChecked && storyData && currentPage !== null) {
      getImageProcessing()
        .catch((error) => {
          console.error('Error during image processing:', error);
          Swal.fire({
            icon: 'warning',
            title: t('error.title'),
            text: t('error.timeoutMessage'),
            confirmButtonText: t('common.ok'),
          });
        });
    }
  }, [quotaChecked, currentPage]);

  const getImageProcessing = async () => {
    console.log('Starting getImageProcessing for currentPage:', currentPage);
    let attempts = 0;
    const maxAttempts = 30;
    const intervalMs = 5000;

    setLoading(true); // 顯示加載狀態

    // 清理舊的 interval
    if (intervalRef.current) {
      console.log('Clearing existing interval:', intervalRef.current);
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }

    const checkProcessingStatus = async () => {
      try {
        const response = await api.get('/api/stories/getImageProcessing', {
          params: { storyListId: storyData.storyList.id, chapterNumber: currentPage },
        });
        console.log('Processing status response:', response.data);

        if (response.data && response.data.processStatus === 'completed') {
          console.log('Image processing completed for chapterNumber:', currentPage);
          setLoading(false); // 停止加載

          // 更新 storyData 和圖片數據
          const updatedStoryData = { ...storyData };
          const chapterIndex = updatedStoryData.storyChapters.findIndex(
            (ch) => ch.chapter_number === response.data.chapterNumber
          );

          if (chapterIndex !== -1) {
            const generatedFilenames = response.data.imageFilenames || [];
            updatedStoryData.storyChapters[chapterIndex].imageFilenames = generatedFilenames;

            setStoryData(updatedStoryData);
            localStorage.setItem('storyData', JSON.stringify(updatedStoryData));

            if (response.data.chapterNumber === currentPage) {
              setCurrentImageIndex(generatedFilenames.length - 1);
              setImageFilenames(generatedFilenames);
            }
          }

          return true; // 完成處理
        }
      } catch (error) {
        console.error('Error during processing status check:', error);
        throw error;
      }

      return false; // 未完成處理
    };

    // 立即執行一次檢查
    const isCompleted = await checkProcessingStatus();
    if (isCompleted) return; // 如果已完成，直接退出

    // 未完成時啟動輪詢
    return new Promise((resolve, reject) => {
      const interval = setInterval(async () => {
        attempts++;
        console.log(`Polling attempt ${attempts} for chapterNumber:`, currentPage);

        try {
          const isCompleted = await checkProcessingStatus();
          if (isCompleted) {
            clearInterval(interval);
            intervalRef.current = null;
            resolve(); // 結束 Promise
          }
        } catch (error) {
          console.error('Error during polling:', error);
          clearInterval(interval);
          intervalRef.current = null;
          reject(error); // 結束 Promise
        }

        if (attempts >= maxAttempts) {
          console.warn('Max attempts reached for chapterNumber:', currentPage);
          clearInterval(interval);
          intervalRef.current = null;
          setLoading(false); // 停止加載
          reject(new Error('Timeout: Image processing did not complete.'));
        }
      }, intervalMs);

      intervalRef.current = interval; // 保存 interval ID
      console.log('Polling started with interval ID:', interval);
    });
  };

  useEffect(() => {
    // 清理 effect 時，確保 interval 被停止
    return () => {
      if (intervalRef.current) {
        console.log('Cleaning up interval on component unmount:', intervalRef.current);
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };
  }, []);

  const generateIllustration = async (regenerate = false) => {
    if (!quotaChecked || !storyData) return;

    const updatedStoryData = { ...storyData };
    const chapterIndex = updatedStoryData.storyChapters.findIndex(
      (ch) => ch.chapter_number === currentPage
    );
    if (chapterIndex === -1) return;

    // 移除當前章節的 selectedImage
    delete updatedStoryData.storyChapters[chapterIndex].selectedImage;
    setStoryData(updatedStoryData);
    localStorage.setItem('storyData', JSON.stringify(updatedStoryData));

    setSelectedImage(null); // 清除選擇的圖片

    try {
      await api.post('/api/stories/generateImage', {
        storyListId: storyData.storyList.id,
        chapterNumber: currentPage,
        prompt: updatedStoryData.storyChapters[chapterIndex].prompt,
        description: storyContent,
        regenerate,
      });

      // 開始輪詢處理
      await getImageProcessing();
    } catch (error) {
      console.error('Error generating or processing illustration:', error);
      Swal.fire({
        icon: 'error',
        title: t('error.title'),
        text: error.message || t('error.generationFailedMessage'),
        confirmButtonText: t('common.ok'),
      });
    }
  };

  const handleImageSelect = (responseIndex, optionIndex) => {
    setSelectedImage({ response_index: responseIndex, option_index: optionIndex });

    const updatedStoryData = { ...storyData };
    const chapterIndex = updatedStoryData.storyChapters.findIndex(
      (ch) => ch.chapter_number === currentPage
    );

    if (chapterIndex !== -1) {
      updatedStoryData.storyChapters[chapterIndex].selectedImage = {
        response_index: responseIndex,
        option_index: optionIndex,
      };
      setStoryData(updatedStoryData);
      localStorage.setItem('storyData', JSON.stringify(updatedStoryData));
    }
  };

  const renderImageSections = (imageUrl, responseIndex) => {
    return [0, 1, 2, 3].map((optionIndex) => (
      <div
        key={`${responseIndex}-${optionIndex}`}
        className={`image-section ${selectedImage?.response_index === responseIndex &&
          selectedImage?.option_index === optionIndex
          ? 'selected'
          : ''
          }`}
        onClick={() => handleImageSelect(responseIndex, optionIndex)}
        style={{
          top: `${Math.floor(optionIndex / 2) * 100 / 2}%`, // 讓區塊填滿圖片的高度
          left: `${(optionIndex % 2) * 100 / 2}%`, // 讓區塊填滿圖片的寬度
          width: '50%',
          height: '50%',
        }}
      >
        {selectedImage?.response_index === responseIndex &&
          selectedImage?.option_index === optionIndex && (
            <div className="checkmark">
              <svg
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
          )}
      </div>
    ));
  };

  const sendSelectedImagesToBackend = async () => {
    if (storyData) {
      const selectedImages = storyData.storyChapters.map((chapter) => ({
        chapterNumber: chapter.chapter_number,
        selectedImage: chapter.selectedImage,
      }));

      try {
        const response = await api.post('/api/stories/updateSelectedImages', {
          storyListId: storyData.storyList.id,
          selectedImages,
        });

        if (response.data && response.data.success) {
          console.log(t('common.imageUpdateSuccess'));
        } else {
          console.error(t('error.imageUpdateFailed'));
        }
      } catch (error) {
        console.error(t('error.imageUpdateError'), error);
      }
    }
  };

  const handleStoryChange = (e) => {
    setStoryContent(e.target.value);
    const updatedStoryData = { ...storyData };
    const chapterIndex = updatedStoryData.storyChapters.findIndex(
      (ch) => ch.chapter_number === currentPage
    );
    if (chapterIndex !== -1) {
      updatedStoryData.storyChapters[chapterIndex].description = e.target.value;
      setStoryData(updatedStoryData);
      localStorage.setItem('storyData', JSON.stringify(updatedStoryData));
    }
  };

  const handlePageChange = (chapterNumber) => {
    setCurrentPage(chapterNumber);
    setCurrentImageIndex(0);
    sendSelectedImagesToBackend();
  };

  useEffect(() => {
    let imageUrl;

    if (imageFilenames.length > 0 && currentImageIndex < imageFilenames.length) {
      const currentFilename = imageFilenames[currentImageIndex];
      loadImage(currentFilename).then((url) => {
        imageUrl = url;
        setGeneratedImageUri(url); // 設置當前圖片的 URL
      });
    } else {
      setGeneratedImageUri('');
    }

    return () => {
      if (imageUrl) {
        URL.revokeObjectURL(imageUrl); // 釋放舊的圖片 URL
      }
    };
  }, [imageFilenames, currentImageIndex]);

  const loadImage = async (filename) => {
    try {
      const response = await api.get(`/api/stories/getfile/image/${filename}`, {
        responseType: 'blob',
      });
      const imageBlob = response.data;
      const imageUrl = URL.createObjectURL(imageBlob);
      return imageUrl;
    } catch (error) {
      console.error(t('error.loadImageFailed'), error);
      return '';
    }
  };

  const allChaptersChecked = useMemo(() => {
    return storyData?.storyChapters?.every(chapter => chapter.selectedImage !== undefined) ?? false;
  }, [storyData]);

  const showWarning = () => {
    Swal.fire({
      icon: 'warning',
      title: t('warning.uncheckedChaptersTitle'),
      text: t('warning.uncheckedChaptersText'),
      confirmButtonText: t('common.ok'),
    });
  };

  const isFormComplete = useMemo(() => {
    console.log("allChaptersChecked:", allChaptersChecked);
    return allChaptersChecked;
  }, [allChaptersChecked]);

  const goToNextStep = () => {
    sendSelectedImagesToBackend();
    if (allChaptersChecked) {
      navigate('/create-story/step6-bookViewer');
    } else {
      showWarning();
    }
  };

  const goBack = () => {
    navigate('/create-story/step4-storyList');
  };

  const showStepGuide = () => {
    Swal.fire({
      title: `
            <div style="display: flex; align-items: center; justify-content: center; gap: 10px;">
                <i class="fas fa-info-circle" style="font-size: 2.5rem; color: #3085d6;"></i>
                <span style="font-size: 1.8rem; font-weight: bold;">${t('step5_picView.selectionGuideTitle')}</span>
            </div>
        `,
      html: `
            <div style="text-align: left; font-size: 1.1rem; line-height: 1.8; padding: 10px;">
                <p style="margin-bottom: 20px;">
                    ${t('step5_picView.selectionGuideText')}
                </p>
                <ol style="padding-left: 20px;">
                    <li style="margin-bottom: 10px;">1. ${t('step5_picView.guideStep1')}</li>
                    <li style="margin-bottom: 10px;">2. ${t('step5_picView.guideStep2')}</li>
                    <li style="margin-bottom: 10px;">3. ${t('step5_picView.guideStep3')}</li>
                </ol>
            </div>
        `,
      icon: '',
      confirmButtonText: t('common.confirm'),
      confirmButtonColor: '#3085d6',
      background: '#2d2d2d',
      color: '#ffffff',
      width: '500px',
      padding: '2rem',
      showClass: {
        popup: 'animate__animated animate__fadeInDown'
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp'
      }
    });
  };

  return (
    <div className="w-[90%] mx-auto">

      <div className="flex items-start justify-center mb-8 relative">
        <div className="flex items-start space-x-8">
          {/* 上一步按鈕 */}
          <div className="fixed bottom-8 left-[35%] z-50">
            <button
              className="bg-gray-500 text-white py-2 px-6 rounded-full text-lg font-semibold shadow-lg border-4 border-white transition duration-300 ease-in-out transform hover:scale-105"
              onClick={goBack}
            >
              {t('common.previous')}
            </button>
          </div>

          {/* 中間導航 */}
          <StepNavigation currentStep={5} />

          {/* 下一步按鈕 */}
          <div className="fixed bottom-8 right-[35%] z-50">
            {isFormComplete && (
              <button
                className="bg-gradient-to-r from-teal-400 to-blue-500 text-white py-2 px-6 rounded-full text-lg font-semibold shadow-lg border-4 border-white transition duration-300 ease-in-out transform hover:scale-105"
                onClick={goToNextStep}
              >
                {t('common.next')}
              </button>
            )}
          </div>
        </div>
      </div>

      <div className="flex flex-wrap justify-center gap-2 w-full max-w-[90vw] mx-auto overflow-hidden">
        {/* 新增的按鈕，帶有箭頭指向右方 */}
        <Button
          variant="contained"
          color="primary"
          endIcon={<ArrowForwardIcon />}
          startIcon={<HelpOutlineIcon />}  // 在按鈕左側新增 Help 符號
          onClick={showStepGuide}
          sx={{
            fontWeight: 'bold',
            textTransform: 'none',
            borderRadius: '20px',
            boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
            '&:hover': { backgroundColor: '#1976d2' }
          }}
        >
          {t('step5_picView.selectionButton')}
        </Button>

        {storyData?.storyChapters.map((chapter) => (
          <button
            key={chapter.chapter_number}
            onClick={() => handlePageChange(chapter.chapter_number)}
            className={`px-3 py-1 rounded-full text-sm ${currentPage === chapter.chapter_number
              ? 'bg-orange-400 text-white'
              : 'bg-gray-700 text-gray-300'
              }`}
          >
            {chapter.chapter_title === 'cover'
              ? t('step5_picView.cover')
              : chapter.chapter_title === 'firstPage'
                ? t('step5_picView.introduction')
                : chapter.chapter_title === 'lastPage'
                  ? t('step5_picView.thankYou')
                  : chapter.chapter_title === 'backcover'
                    ? t('step5_picView.backCover')
                    : `P${chapter.chapter_number}`}
            {chapter.selectedImage && (
              <span className="ml-2" style={{ color: '#32CD32', fontWeight: 'bold' }}>✔</span>
            )}
          </button>
        ))}
      </div>

      <div className="text-center mb-4">
        <span className="text-yellow-500 text-lg font-bold mr-2">{t('step5_picView.story')}</span>
        <textarea
          value={storyContent}
          onChange={handleStoryChange}
          className="w-full p-3 border border-gray-600 rounded-lg bg-gray-700 text-gray-200 resize-none"
          rows="3"
        />
      </div>

      <div className="text-center mb-8">
        {loading ? (
          <div className="flex justify-center items-center">
            <ReactLoading type="spin" color="#123abc" height={50} width={50} />
          </div>
        ) : (
          imageFilenames.length < 3 && ( // 檢查 imageFilenames.length 是否超過 3
            <button
              className="bg-orange-500 text-white py-2 px-6 rounded-full text-lg"
              onClick={() => generateIllustration(true)}
            >
              {t('step5_picView.generateIllustration')}
            </button>
          )
        )}
        {imageFilenames.length < 30 && ( // 提示文字也應根據按鈕顯示與否決定
          <p className="text-gray-400 mt-2">
            {t('step5_picView.regenerateIllustrationInfo')}
          </p>
        )}
      </div>

      <p className="text-center text-yellow-500 mb-4">{t('step5_picView.chooseImage')}</p>

      {imageFilenames.length > 0 && (
        <>
          <div className="flex justify-center mb-4">
            <Pagination
              count={imageFilenames.length}
              page={currentImageIndex + 1}
              onChange={(event, page) => {
                console.log('Pagination clicked, new page:', page); // 確認是否觸發
                setCurrentImageIndex(page - 1);
              }}
              variant="outlined"
              shape="rounded"
              sx={{
                '& .MuiPaginationItem-root': {
                  color: '#ffffff',
                  borderColor: '#bdbdbd',
                  fontWeight: 'bold',
                  transition: 'all 0.2s ease-in-out',
                },
                '& .MuiPaginationItem-root.Mui-selected': { // 這裡改用更精確的選擇器
                  backgroundColor: '#1976d2',
                  color: '#ffffff',
                  borderColor: '#1565c0',
                  fontWeight: 'bold',
                  '&:hover': {
                    backgroundColor: '#1565c0',
                  },
                },
                '& .MuiPaginationItem-root:hover': {
                  backgroundColor: '#424242',
                },
              }}
            />
          </div>
          <div className="image-container">
            <img
              src={generatedImageUri}
              alt="Generated Illustration"
              className="full-image"
            />
            {renderImageSections(generatedImageUri, currentImageIndex)}
          </div>
        </>
      )}

      <div style={{ marginBottom: '50px' }}></div>
    </div>
  );
};

export default Step5_picView;
